import React from "react";
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container} from "react-bootstrap"
import { Slide } from 'react-slideshow-image';

const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: true,
    pauseOnHover: true,
    onChange: (oldIndex, newIndex) => {
      // console.log(`slide transition from ${oldIndex} to ${newIndex}`);
    }
  }

const Galeria = ({data}) => {
  return(
	<Layout pageInfo={{ pageName: "galeria" }}>
		<SEO title="Galeria" descriptiom="Galeria przedstawiająca wykoanane projekty. Sprawdź nasze realizacje i dołącz do grona zadowolonych klientów"/>
    <Container fluid className="info-bar p-4">
			<div>
				<b>SKONTAKTUJ SIĘ Z NAMI - PRZYGOTUJEMY WYCENĘ TWOJEGO DACHU</b>
			</div>
		</Container>
		<Container>
    <div className="divider">Galeria zdjęć</div>
			<p className="subtitle par-grey fw-400 fs-125 mb-100">Przykładowe realizacje naszych projektów</p>
        <div className="slide-container">
            <Slide {...properties}>
            {
                data.slider.nodes.map((each, index) => 
                    <div className="each-slide" key={index}>
                      <div className="slider-img" style={{'backgroundImage': `url(${each.childImageSharp.fluid.src})`}}>
                      </div>
                    </div>
                )
            }
            </Slide>
        </div>
		</Container>
	</Layout>
)}

export const images = graphql`
{
  slider : allFile(filter: {relativeDirectory: {eq: "slider"}}) {
    nodes {
      relativePath
      id
      name
      childImageSharp {
        fluid {
            src
        }
      }
    }
  }
}`

export default Galeria
